<template>
  <div>
    <img src="@/assets/solutionpdf/medicine_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/medicine_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/medicine_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/medicine_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/medicine_5.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "medicine-solution",
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>